export const Navigation = [
    {
        to: "/tableau-de-bord",
        itemTitle: "Accueil",
        does: ["true"],
    },
    {
        to: ["/clients", "/contacts"],
        itemTitle: "Clients",
        does: ["get_clients", "get_contacts"],
        dropDown: [
            {
                to: "/clients",
                itemTitle: "Clients",
                does: ["get_clients"],
            },
            {
                to: "/contacts",
                itemTitle: "Contacts",
                does: ["get_contacts"],
            },
        ],
    },
    {
        to: "/fournisseurs",
        itemTitle: "Fournisseurs",
        does: ["get_suppliers"],
        premium: true,
    },
    {
        to: "/produits",
        itemTitle: "Produits",
        does: ["get_sobrus_products"],
    },
    {
        to: [
            "/ventes/commandes-clients",
            "/ventes/bon-de-livraison",
            "/ventes/facture-client",
            "/ventes/avoirs-client",
            "/ventes/bon-de-retour",
        ],
        itemTitle: "Ventes",
        does: [
            "get_client_orders",
            "get_all_client_delivery_notes",
            "get_all_client_invoices",
            "get_all_client_issued_return",
            "get_all_client_received_return",
            "app_client_pricingget_client_pricing_list",
        ],
        dropDown: [
            {
                to: "/ventes/offers",
                itemTitle: "Offres",
                does: ["get_offers_list"],
            },
            {
                to: "/ventes/quotation",
                itemTitle: "Devis",
                does: ["app_client_pricingget_client_pricing_list"],
                premium: true,
            },
            {
                to: "/ventes/commandes-clients",
                itemTitle: "Commandes clients",
                does: ["get_client_orders"],
            },
            {
                to: "/ventes/bon-de-livraison",
                itemTitle: "Bons de livraison",
                does: ["get_all_client_delivery_notes"],
                premium: true,
            },
            {
                to: "/ventes/bordereaux",
                itemTitle: "Bordereaux d'expédition",
                does: ["get_all_client_delivery_notes"],
                premium: true,
            },
            {
                to: "/ventes/facture-client",
                itemTitle: "Factures",
                does: ["get_all_client_invoices"],
                premium: true,
            },
            {
                to: "/ventes/bordereaux-cheque",
                itemTitle: "Bordereaux de chèques",
                does: ["get_all_client_delivery_notes"],
                premium: true,
            },
            {
                to: "/ventes/avoirs-client",
                itemTitle: "Avoirs clients",
                does: ["get_all_client_issued_return"],
                premium: true,
            },
            {
                to: "/ventes/bon-de-retour",
                itemTitle: "Bons de retour",
                does: ["get_all_client_received_return"],
                premium: true,
            },
        ],
    },
    {
        to: [
            "/achats/offers",
            "/achats/proposition-commande",
            "/achats/bons-de-commande",
            "/achats/bons-de-reception",
            "/achats/factures-fournisseur",
            "/achats/avoir-emis",
            "/achats/avoir-recu",
            "/achats/bons-de-sortie",
        ],
        itemTitle: "Achats",
        does: [
            "get_all_supplier_purchase_suggestion",
            "get_supplier_purchase_order",
            "get_all_supplierdeliverynotes",
            "get_supplier_invoice",
            "get_all_supplier_issued_return",
            "get_all_supplier_received_return",
            "get_release_voucher",
        ],
        premium: true,
        dropDown: [
            {
                to: "/achats/offers",
                itemTitle: "Offres",
                does: ["get_all_supplier_purchase_suggestion"],
                premium: true,
            },
            {
                to: "/achats/marketplace-commande",
                itemTitle: "Commandes Marketplace",
                does: ["get_all_supplier_purchase_suggestion"],
                premium: true,
            },
            {
                to: "/achats/proposition-commande",
                itemTitle: "Propositions de commande",
                does: ["get_all_supplier_purchase_suggestion"],
                premium: true,
            },
            {
                to: "/achats/demande-prix",
                itemTitle: "Demandes de prix",
                does: ["app_client_pricingget_supplier_price_request_list"],
                premium: true,
            },
            {
                to: "/achats/bons-de-commande",
                itemTitle: "Bons de commande",
                does: ["get_supplier_purchase_order"],
                premium: true,
            },
            {
                to: "/achats/bons-de-reception",
                itemTitle: "Bons de réception",
                does: ["get_all_supplierdeliverynotes"],
                premium: true,
            },
            {
                to: "/achats/factures-fournisseur",
                itemTitle: "Factures fournisseurs",
                does: ["get_supplier_invoice"],
                premium: true,
            },
            {
                to: "/achats/avoir-emis",
                itemTitle: "Avoirs fournisseurs émis",
                does: ["get_all_supplier_issued_return"],
                premium: true,
            },
            {
                to: "/achats/bons-de-sortie",
                itemTitle: "Bons de sortie",
                does: ["get_release_voucher"],
                premium: true,
            },
            {
                to: "/achats/avoir-recu",
                itemTitle: "Avoirs fournisseurs reçus",
                does: ["get_all_supplier_received_return"],
                premium: true,
            },
        ],
    },
    {
        to: "/requests",
        itemTitle: "Demandes",
        does: ["get_all_demands"],
        premium: true,
    },
    {
        to: ["/stock", "/inventaires"],
        itemTitle: "Stock",
        does: ["get_stocks", "get_inventories_list"],
        premium: true,
        dropDown: [
            {
                to: "/stock",
                itemTitle: "Stock",
                does: ["get_stocks"],
                premium: true,
            },
            {
                to: "/inventaires",
                itemTitle: "Inventaires",
                does: ["get_inventories_list"],
                premium: true,
            },
        ],
    },
    {
        to: "/transferts-entrepot",
        itemTitle: "Transferts d’entrepôt",
        does: ["get_all_stock_transfers"],
        premium: true,
    },
    {
        to: "/parc-auto",
        itemTitle: "Parc auto",
        does: ["get_vehicles"],
        premium: true,
    },
    // {
    //     to: "/logs-erreurs",
    //     itemTitle: " Logs d'erreurs",
    //     does: ["get_all_log"],
    // },
    {
        to: "/rapports",
        itemTitle: "Rapports",
        does: [
            "get_sales_reports",
            "get_purchases_reports",
            "get_clients_reports",
            "get_turnovers_online_order_reports_by_status",
            "get_turnovers_online_order_reports_by_client",
            "get_turnovers_online_order_reports_by_product",
            "get_online_order_errors_report",
            "get_online_order_product_quatities_gap_report",
        ],
    },
    {
        to: "/parametres",
        itemTitle: "Paramètres",
        does: ["true"],
    },
];
