import { SpinnerLoading, Toast } from "@sobrus-com/sobrus-design-system-v2";
import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router";
import { UserContext } from "../context/userContext";
import "../styles/sns.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { PrivateRoute } from "./RouteType";
import Header from "../pages/Nav";
import { NavPaths2 } from "values/NavPaths2";
import { NavPaths } from "values/NavPaths";
import "../styles/popUps/PopUps.scss";
import poppinsRegular from "fonts/Poppins-Regular.ttf";
import poppinsItalic from "fonts/Poppins-Italic.ttf";
import poppinsLight from "fonts/Poppins-Light.ttf";
import poppinsLightItalic from "fonts/Poppins-LightItalic.ttf";
import poppinsMedium from "fonts/Poppins-Medium.ttf";
import poppinsMediumItalic from "fonts/Poppins-MediumItalic.ttf";
import poppinsSemiBold from "fonts/Poppins-SemiBold.ttf";
import poppinsSemiBoldItalic from "fonts/Poppins-SemiBoldItalic.ttf";
import poppinsBold from "fonts/Poppins-Bold.ttf";
import poppinsBoldItalic from "fonts/Poppins-BoldItalic.ttf";
import { Font } from "@react-pdf/renderer";
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
// import { Review } from "@sobrus-com/sobrus-surveys-package"
import Page404 from "pages/404/404";
import Errorpage from "pages/ErrorPage/ErrorPage";
import FailureComponent from "pages/ErrorPage/FailureComponent";
import ConnexionDetector from "components/ConnexionDetector/ConnexionDetector";
import { Review } from "@sobrus-com/sobrus-surveys-package";
import { BASE_URL } from "env";
// import { useAutoAnimate } from "@formkit/auto-animate/react";
const Signin = lazy(() => import("../pages/Signin"));
const CountryVerification = lazy(() => import("./CountryVerification"));
const DeniedAccess = lazy(() => import("../pages/DeniedAccess/DeniedAccess"));
const PremiumFeats = lazy(() => import("pages/premium/PremiumFeats"));
const Offers = lazy(() => import("pages/achats/offers/Offers"));
const OrderAdd = lazy(() => import("pages/achats/offers/orderAdd/OrderAdd"));
const OrderAddStep1 = lazy(() => import("pages/achats/offers/orderAdd/OrderAddStep1"));
const OrderAddStep2 = lazy(() => import("pages/achats/offers/orderAdd/OrderAddStep2"));
const OrderAddStep3 = lazy(() => import("pages/achats/offers/orderAdd/OrderAddStep3"));
const VOrderAdd = lazy(() => import("pages/ventes/CommandesClients/marketplaceOrderAdd/VOrderAdd"));
const VOrderAddStep1 = lazy(() => import("pages/ventes/CommandesClients/marketplaceOrderAdd/VOrderAddStep1"));
const VOrderAddStep2 = lazy(() => import("pages/ventes/CommandesClients/marketplaceOrderAdd/VOrderAddStep2"));
const VOrderAddStep3 = lazy(() => import("pages/ventes/CommandesClients/marketplaceOrderAdd/VOrderAddStep3"));
const ImportClients = lazy(() => import("pages/clients/importClients/ImportClients"));
const ImportClientsStep1 = lazy(() => import("pages/clients/importClients/ImportClientsStep1"));
const ImportClientsStep2 = lazy(() => import("pages/clients/importClients/ImportClientsStep2"));
const ImportClientsStep3 = lazy(() => import("pages/clients/importClients/ImportClientsStep3"));
const ImportClientsResults = lazy(() => import("pages/clients/importClients/ImportClientsResults"));
const ImportsHistory = lazy(() => import("pages/clients/importClients/ImportsHistory"));
const ImportHistoryDetails = lazy(() => import("pages/clients/importClients/ImportHistoryDetails"));

Font.register({
    family: "Poppins",
    fonts: [
        { src: poppinsRegular }, // font-style: normal, font-weight: normal
        { src: poppinsItalic, fontStyle: "italic" },
        { src: poppinsLight, fontStyle: "normal", fontWeight: 300 },
        { src: poppinsLightItalic, fontStyle: "italic", fontWeight: 300 },
        { src: poppinsMedium, fontStyle: "normal", fontWeight: 500 },
        { src: poppinsMediumItalic, fontStyle: "italic", fontWeight: 500 },
        { src: poppinsSemiBold, fontStyle: "normal", fontWeight: 600 },
        { src: poppinsSemiBoldItalic, fontStyle: "italic", fontWeight: 600 },
        { src: poppinsBold, fontStyle: "normal", fontWeight: 700 },
        { src: poppinsBoldItalic, fontStyle: "italic", fontWeight: 700 },
    ],
});

const HomeNavigation = () => {
    const { isLogin, user } = useContext(UserContext);
    const [showNav, setShowNav] = useState(false);
    // const [parent, enableAnimations] = useAutoAnimate((el, action, oldCoords, newCoords) => {
    //     let keyframes;
    //     if (action === "add") {
    //         keyframes = [{ opacity: 0 }, { transform: "scale(1)", opacity: 1 }];
    //     }
    //     if (action === "remove") {
    //         keyframes = [
    //             { transform: "scale(1)", opacity: 1 },
    //             { transform: "scale(0.99)", opacity: 0 },
    //         ];
    //     }
    //     return new KeyframeEffect(el, keyframes, { duration: 300, easing: "ease-in-out" });
    // });

    useEffect(() => {
        setTimeout(() => {
            setShowNav(true);
        }, 100);

        Aos.init({
            anchorPlacement: "bottom-center",
        });

        window.addEventListener("load", Aos.refresh);

        return () => {
            window.removeEventListener("load", Aos.refresh);
        };
    }, []);

    const router = createBrowserRouter([
        {
            element: (
                <>
                    {isLogin &&
                        window.location.pathname !== "/denied_access" &&
                        window.location.pathname !== "/404" &&
                        showNav && <Header user={user} />}
                    {isLogin &&
                        window.location.pathname !== "/denied_access" &&
                        window.location.pathname !== "/404" && <Review baseUrl={BASE_URL} backgroundColor="#18b1d4" />}
                    <Outlet />
                </>
            ),
            loader: Loader,
            children: [
                {
                    path: "*",
                    element: (
                        <Routes>
                            <Route path="/" element={<Navigate to="/tableau-de-bord" replace />} />
                            {NavPaths.map(
                                ({ path, Component, canDoes, premium }, index) =>
                                    !(user?.subscriptionType === "free" && premium) && (
                                        <Route
                                            key={index + "route1"}
                                            path={path}
                                            element={
                                                <PrivateRoute
                                                    component={
                                                        <Suspense fallback={<Loader />}>
                                                            <Component />
                                                        </Suspense>
                                                    }
                                                    canDoes={canDoes}
                                                />
                                            }
                                        />
                                    )
                            )}
                            <Route
                                path="/clients/import"
                                element={<PrivateRoute component={<ImportClients />} acl={" "} />}
                            >
                                <Route index element={<ImportClientsStep1 />} />
                                <Route path="/clients/import/step1" element={<ImportClientsStep1 />} />
                                <Route path="/clients/import/step2" element={<ImportClientsStep2 />} />
                                <Route path="/clients/import/step3/:id" element={<ImportClientsStep3 />} />
                                <Route path="/clients/import/results/:id" element={<ImportClientsResults />} />
                            </Route>
                            <Route
                                path="/clients/import/history"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <ImportsHistory />
                                            </Suspense>
                                        }
                                        acl={" "}
                                    />
                                }
                            />
                            <Route
                                path="/clients/import/history/:id"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <ImportHistoryDetails />
                                            </Suspense>
                                        }
                                        acl={" "}
                                    />
                                }
                            />
                            <Route
                                path="/achats/offers"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <Offers />
                                    </Suspense>
                                }
                            />
                            {/* Achats Orders */}
                            <Route
                                path="/achats/offers/details/:id"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <OrderAdd />
                                    </Suspense>
                                }
                            >
                                <Route index element={<Navigate to="/achats/offers/details/:id/step1" />} />
                                <Route
                                    path="/achats/offers/details/:id/step1"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <OrderAddStep1 />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/achats/offers/details/:id/step2"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <OrderAddStep2 />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/achats/offers/details/:id/step3"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <OrderAddStep3 />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            {/* Ventes Orders */}
                            <Route
                                path="/ventes/commandes-clients/marketplace"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <VOrderAdd />
                                    </Suspense>
                                }
                            >
                                <Route index element={<Navigate to="/ventes/commandes-clients/marketplace/step1" />} />
                                <Route
                                    path="/ventes/commandes-clients/marketplace/step1"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <VOrderAddStep1 />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/ventes/commandes-clients/marketplace/step2"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <VOrderAddStep2 />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/ventes/commandes-clients/marketplace/step3"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <VOrderAddStep3 />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            {NavPaths2.map(({ path, Component, canDoes }, index) => (
                                <Route
                                    key={index + "route2"}
                                    path={path}
                                    element={
                                        <PrivateRoute
                                            component={
                                                <Suspense fallback={<Loader />}>
                                                    <Component />
                                                </Suspense>
                                            }
                                            canDoes={canDoes}
                                        />
                                    }
                                />
                            ))}
                            <Route
                                path="/premium"
                                element={
                                    <PrivateRoute
                                        component={
                                            <Suspense fallback={<Loader />}>
                                                <PremiumFeats />
                                            </Suspense>
                                        }
                                        canDoes={""}
                                    />
                                }
                            />
                            {/* SignIn */}
                            <Route
                                path="/signin"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <Signin />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/auth/country-verification"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <CountryVerification />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/denied_access"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <DeniedAccess />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/auth/failed"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <FailureComponent />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/error"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <Errorpage />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <Suspense fallback={<Loader />}>
                                        <Page404 />
                                    </Suspense>
                                }
                            />
                        </Routes>
                    ),
                },
            ],
        },
    ]);

    return (
        <div className="sns">
            <ConnexionDetector />
            <Toast />
            <RouterProvider router={router} />
        </div>
    );
};

const Loader = () => (
    <div className="sns">
        <div className="loader">
            <SpinnerLoading loading />
        </div>
    </div>
);

export default HomeNavigation;
